import React from "react";
import logo from "#assets/textLogo.svg";
import { getMaterialIcon } from "#utils/icons";
const EmailOutlinedIcon = getMaterialIcon("EmailOutlined");

const Footer = () => {
  return (
    <div className="relative bottom-0 bg-white border-t w-screen flex flex-col justify-center items-center px-[45px] z-20">
      <div className="flex w-full h-fit md:w-[700px] justify-between mt-[60px] md:mt-[73px]">
        <div className="flex flex-col h-full items-center justify-between">
          <a href="/" className="self-start">
            <img src={logo} alt="logo" className="w-[90px]" />
          </a>
          <p className="text-[11px] md:text-[13px] leading-none mt-[20px]">© 2024 Pagebound</p>
        </div>
        <a href="https://buymeacoffee.com/pagebound" target="_blank" rel="noreferrer" className="hidden md:block text-sm self-center">
          Buy Lucy & Jennifer a coffee ☕️
        </a>
        <div className="flex h-fit flex-col gap-3 items-end justify-between">
          <a href="https://support.pagebound.co/FAQ" target="_blank" rel="noreferrer" className="text-[11px] md:text-[13px]">
            FAQ
          </a>
          <a href="https://support.pagebound.co/terms_of_use" target="_blank" rel="noreferrer" className="text-[11px] md:text-[13px]">
            Terms of Use
          </a>
          <a href="https://support.pagebound.co/privacy_policy" target="_blank" rel="noreferrer" className="text-[11px] md:text-[13px]">
            Privacy Policy
          </a>
          <a href="https://support.pagebound.co/content_policy" target="_blank" rel="noreferrer" className="text-[11px] md:text-[13px]">
            Content Policy
          </a>
          <a href="/authors" target="_blank" className="text-white hover:text-white text-[11px] md:text-[13px]">
            Our Authors
          </a>
        </div>
      </div>
      <a href="https://buymeacoffee.com/pagebound" target="_blank" rel="noreferrer" className="md:hidden text-xs self-center my-3">
        Buy Lucy & Jennifer a coffee ☕️
      </a>
      <div className="flex gap-4 mt-3 mb-[40px] md:mb-[73px]">
        <a
          href="https://www.instagram.com/pagebound.co"
          target="_blank"
          rel="noreferrer"
          className="rounded-full bg-fuschia md:w-[52px] md:h-[52px] w-[40px] h-[40px] flex justify-center items-center"
        >
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="26" height="26" viewBox="0 0 32 32">
            <g fill="#ffffff">
              <path d="M 11.46875 5 C 7.917969 5 5 7.914063 5 11.46875 L 5 20.53125 C 5 24.082031 7.914063 27 11.46875 27 L 20.53125 27 C 24.082031 27 27 24.085938 27 20.53125 L 27 11.46875 C 27 7.917969 24.085938 5 20.53125 5 Z M 11.46875 7 L 20.53125 7 C 23.003906 7 25 8.996094 25 11.46875 L 25 20.53125 C 25 23.003906 23.003906 25 20.53125 25 L 11.46875 25 C 8.996094 25 7 23.003906 7 20.53125 L 7 11.46875 C 7 8.996094 8.996094 7 11.46875 7 Z M 21.90625 9.1875 C 21.402344 9.1875 21 9.589844 21 10.09375 C 21 10.597656 21.402344 11 21.90625 11 C 22.410156 11 22.8125 10.597656 22.8125 10.09375 C 22.8125 9.589844 22.410156 9.1875 21.90625 9.1875 Z M 16 10 C 12.699219 10 10 12.699219 10 16 C 10 19.300781 12.699219 22 16 22 C 19.300781 22 22 19.300781 22 16 C 22 12.699219 19.300781 10 16 10 Z M 16 12 C 18.222656 12 20 13.777344 20 16 C 20 18.222656 18.222656 20 16 20 C 13.777344 20 12 18.222656 12 16 C 12 13.777344 13.777344 12 16 12 Z"></path>
            </g>
          </svg>
        </a>
        <a
          href="https://www.tiktok.com/@pagebound.co"
          target="_blank"
          rel="noreferrer"
          className="rounded-full bg-fuschia md:w-[52px] md:h-[52px] w-[40px] h-[40px] flex justify-center items-center"
        >
          <svg
            viewBox="0 0 19.738 22.466"
            height="20.466"
            width="16.738"
            xmlns="http://www.w3.org/2000/svg"
            data-name="Group 101"
            id="Group_101"
          >
            <path
              fill="#ffffff"
              transform="translate(-31.423 -0.39)"
              d="M51.151,6.015a5.661,5.661,0,0,1-3.42-1.143A5.662,5.662,0,0,1,45.469.39H41.8V10.414l0,5.49a3.325,3.325,0,1,1-2.281-3.151V9.029a7.218,7.218,0,0,0-1.058-.078,7.034,7.034,0,0,0-5.286,2.364,6.893,6.893,0,0,0,.311,9.505,7.158,7.158,0,0,0,.663.579,7.035,7.035,0,0,0,4.312,1.458,7.219,7.219,0,0,0,1.058-.078,7.011,7.011,0,0,0,3.917-1.959,6.868,6.868,0,0,0,2.06-4.887l-.019-8.2a9.3,9.3,0,0,0,5.688,1.933V6.014h-.011Z"
              data-name="Path 6566"
              id="Path_6566"
            ></path>
          </svg>
        </a>
        <a
          href="https://support.pagebound.co/contact"
          target="_blank"
          rel="noreferrer"
          className="rounded-full bg-fuschia md:w-[52px] md:h-[52px] w-[40px] h-[40px] flex justify-center items-center"
        >
          <EmailOutlinedIcon sx={{ color: "#ffffff" }} fontSize="small" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
