export const API_URL = process.env.NODE_ENV === "test" ? "mockedurl" : import.meta.env?.VITE_API_URL;

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const CLUB_THEMES = {
  theme1: "bg-gradient-to-b from-pastelBlue to-pastelPink",
  theme2: "bg-gradient-to-b from-pastelOrange to-pastelGreen",
  theme3: "bg-gradient-to-b from-pastelBlue to-pastelGreen",
  theme4: "bg-gradient-to-b from-pastelPurple to-pastelGreen",
  theme5: "bg-gradient-to-b from-pastelOrange to-pastelPurple",
  theme6: "bg-gradient-to-b from-pastelOrange to-pastelBlue",
};

export const AVATARS = {
  angel: "/assets/angel.png",
  alien: "/assets/alien.png",
  genie: "/assets/genie.png",
  geniegirl: "/assets/geniegirl.png",
  devil: "/assets/devil.png",
  vampire: "/assets/vampire.png",
  vampiregirl: "/assets/vampiregirl.png",
  witch: "/assets/witch.png",
  wizard: "/assets/wizard.png",
  elf: "/assets/elf.png",
  fairy: "/assets/fairy.png",
  ghost: "/assets/ghost.png",
  knight: "/assets/knight.png",
  mermaid: "/assets/mermaid.png",
  merman: "/assets/merman.png",
  monster: "/assets/monster.png",
  unicorn: "/assets/unicorn.png",
  ninja: "/assets/ninja.png",
  princess: "/assets/princess.png",
  prince: "/assets/prince.png",
  punk: "/assets/punk.png",
  robot: "/assets/robot.png",
  romancegirl: "/assets/romancegirl.png",
  superhero: "/assets/superhero.png",
  dragon: "/assets/dragon.png",
  detective: "/assets/detective.png",
  emoboy: "/assets/emoboy.png",
  emogirl: "/assets/emogirl.png",
};

export const BOOK_STATUS_BORDER_COLOR = {
  tbr: "border-tbr",
  current: "border-current",
  finished: "border-finished",
  dnf: "border-dnf",
  none: "border-white",
  "set-status": "border-white",
};

export const BOOK_STATUS_BG_COLOR = {
  tbr: "bg-tbr",
  current: "bg-current",
  finished: "bg-finished",
  dnf: "bg-dnf",
  none: "bg-white",
  "set-status": "bg-white",
};

export const BOOK_STATUS_TEXT_MAP = {
  tbr: "tbr",
  current: "reading",
  finished: "finished",
  dnf: "dnf",
  none: "set status",
  "set-status": "set status",
};

export const BOOK_STATUS_LABEL_MAP = {
  tbr: "TBR (To Be Read)",
  current: "Reading",
  finished: "Finished",
  dnf: "DNF (Did Not Finish)",
};

export const BOOK_STATUS_ICON_MAP = {
  tbr: "/assets/bookIcon.png",
  current: "/assets/eye.png",
  finished: "/assets/Finished.png",
  dnf: "/assets/DNF.png",
  none: "/assets/elipses.png",
  "set-status": "/assets/elipses.png",
};

// reverse order, most recent first
export const READALONG_SEASONS = ["Winter 2024", "Fall 2024"];
