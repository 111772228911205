import { useState, useEffect, useRef } from "react";
import Button from "#helpers/Button";
import Switch from "#helpers/Switch";
import { useDispatch } from "react-redux";
import { newPost } from "#redux/slices/forumSlice";
import api from "#utils/api";
import { Menu, MenuItem } from "@mui/material";
import { getMaterialIcon } from "#utils/icons";
const ExpandMoreOutlinedIcon = getMaterialIcon("ExpandMoreOutlined");

const NewPostForm = ({ forum, onSubmit, isCurrentRead }) => {
  const dispatch = useDispatch();
  const textareaRef = useRef(null);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [postTitle, setPostTitle] = useState(isCurrentRead ? "Reading Update" : "");
  const [postContent, setPostContent] = useState("");
  const [isSpoiler, setIsSpoiler] = useState(false);
  const [currentPage, setCurrentPage] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [currentPercent, setCurrentPercent] = useState("");
  const [readingUpdatePercent, setReadingUpdatePercent] = useState();
  const [progressType, setProgressType] = useState("pages");

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto"; // Reset height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set height based on content
    }
  }, [postContent]);

  useEffect(() => {
    if (!isCurrentRead) return;

    const currentNum = Number(currentPage);
    const totalNum = Number(totalPages);
    const percentNum = Number(currentPercent);

    if (progressType === "pages") {
      if (currentNum > 0 && currentNum <= totalNum) {
        const percent = Math.round((currentNum / totalNum) * 100);
        setPostTitle(`Reading Update from ${percent}% (page ${currentNum})`);
        setReadingUpdatePercent(percent);
      } else {
        setReadingUpdatePercent();
        setPostTitle("Reading Update");
      }
    }

    if (progressType === "percent") {
      if (percentNum > 0 && percentNum <= 100) {
        setPostTitle(`Reading Update from ${percentNum}%`);
        setReadingUpdatePercent(percentNum);
      } else {
        setReadingUpdatePercent();
        setPostTitle("Reading Update");
      }
    }
  }, [currentPercent, currentPage, totalPages, progressType]);

  const handleCreatePost = (event) => {
    event.preventDefault();

    const postData = {
      forum_id: forum.id,
      title: postTitle,
      content: postContent,
      is_spoiler: isSpoiler,
      reading_update_percent: readingUpdatePercent,
    };

    api
      .post(`/posts`, { post: postData })
      .then((data) => {
        dispatch(newPost(data));
        onSubmit && onSubmit(data);
      })
      .catch((error) => {
        console.error("Error creating post", error);
      })
      .finally(() => {
        setPostTitle("");
        setPostContent("");

        setIsSpoiler(false);
      });
  };

  const handleToggle = () => {
    setIsSpoiler((prevState) => !prevState);
  };

  const handleChangeContent = (event) => {
    setPostContent(event.target.value);
  };

  const handleChangeTitle = (event) => {
    setPostTitle(event.target.value);
  };

  const handleMenuClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setMenuAnchor(event.currentTarget);
  };
  const handleMenuClose = (event, type) => {
    event.preventDefault();
    event.stopPropagation();

    setMenuAnchor(null);
    if (type === "pages" || type === "percent") {
      setProgressType(type);
    }
  };

  return (
    <form className="bg-white text-offBlack flex flex-col gap-3 font-hanken text-base">
      {isCurrentRead && (
        <>
          <hr className="text-gray my-4" />
          <div className="flex flex-row justify-between">
            <div>
              <b>Progress:</b>
              <div className="text-xs">(optional)</div>
            </div>
            <div className="flex flex-row gap-3">
              {progressType === "pages" && (
                <>
                  <input
                    onChange={(e) => setCurrentPage(e.target.value)}
                    type="number"
                    value={currentPage}
                    placeholder="0"
                    id="current-page"
                    className="w-[50px] rounded-[15px] ml-0 p-2 my-auto [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  />
                  <div className="self-center">of</div>
                  <input
                    onChange={(e) => setTotalPages(e.target.value)}
                    type="number"
                    value={totalPages}
                    placeholder="0"
                    id="total-pages"
                    className="w-[50px] rounded-[15px] ml-0 p-2 my-auto [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  />
                </>
              )}
              {progressType === "percent" && (
                <input
                  onChange={(e) => setCurrentPercent(e.target.value)}
                  type="number"
                  value={currentPercent}
                  placeholder="0"
                  id="current-percent"
                  className="w-[50px] rounded-[15px] ml-0 p-2 my-auto [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                />
              )}
              <button onClick={handleMenuClick} id="progress-menu-button" className="flex flex-none items-center gap-1">
                {progressType === "pages" ? "pages" : "%"} <ExpandMoreOutlinedIcon fontSize="small" />
              </button>
              <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={handleMenuClose}
                onClick={(e) => e.stopPropagation()}
                slotProps={{ paper: { className: `!rounded-default md:p-1` } }}
              >
                <MenuItem
                  classes={{ root: `!rounded-default hover:!bg-mainGreen` }}
                  onClick={(e) => handleMenuClose(e, "pages")}
                >
                  pages
                </MenuItem>
                <MenuItem
                  classes={{ root: `!rounded-default hover:!bg-mainGreen` }}
                  onClick={(e) => handleMenuClose(e, "percent")}
                >
                  %
                </MenuItem>
              </Menu>
            </div>
          </div>
        </>
      )}
      <input
        onChange={handleChangeTitle}
        type="text"
        value={postTitle}
        placeholder={isCurrentRead ? "Reading Update from xx%" : "Post Title"}
        id="title"
        className="py-3 px-4 my-auto"
      />
      <textarea
        ref={textareaRef}
        placeholder="What's on your mind?"
        value={postContent}
        onChange={handleChangeContent}
        className="min-h-20 py-3 px-4 my-auto"
      />
      <div className="flex items-center">
        <label className="mr-4 font-bold text-sm md:text-base" htmlFor="hasSpoilers">
          Includes spoilers?
        </label>
        <Switch id="hasSpoilers" isChecked={isSpoiler} onChange={handleToggle} />
      </div>

      <div className="text-right">
        <Button disabled={postTitle.length === 0 || postContent.length === 0} text="Post" onClick={handleCreatePost} />
      </div>
    </form>
  );
};

export default NewPostForm;
