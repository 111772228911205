import React from "react";
import pluralize from "pluralize";
import BookCover from "#helpers/BookCover";

const QuestCard = ({ quest, variant }) => {
  const numBooksText = pluralize("books", quest.goal, true);

  const joinButton = () => {
    if (quest.joined) {
      if (quest.user_challenge?.status === "in_progress") {
        return (
          <div className="bg-yellow rounded-default border-2 border-orange px-4 py-1">
            <p className="leading-tight text-[11px] md:text-[13px]">
              {quest.user_challenge.completed_books_count} of {quest.goal} read
            </p>
          </div>
        );
      } else {
        return (
          <div className="bg-yellow rounded-default border-2 border-orange px-4 py-1">
            <p className="leading-tight text-[11px] md:text-[13px]">Joined</p>
          </div>
        );
      }
    }

    if (quest.joined && quest.user_challenge?.status === "finished") {
      return (
        <div className="bg-mainGreen rounded-default border border-offBlack px-4 py-1">
          <p className="leading-tight text-[11px] md:text-[13px]">Finished</p>
        </div>
      );
    }

    return (
      <div className="bg-beige rounded-default border border-offBlack px-4 py-1">
        <p className="leading-tight text-[11px] md:text-[13px]">Not Joined</p>
      </div>
    );
  };

  if (variant === "small") {
    return (
      <a
        href={`/quests/${quest.uuid}`}
        className="no-hover relative w-[190px] h-[320px] flex flex-col bg-white border border-offBlack shadow-main rounded-default p-6 gap-3 cursor-pointer items-center"
      >
        <div className="grid grid-cols-2 gap-2 w-fit">
          {quest.book_details?.map((book, index) => (
            <BookCover key={index} book={book} size="w-[54px] h-[80px]" clickable={false} />
          ))}
        </div>
        <div className="flex flex-col flex-grow text-center w-full justify-between">
          <div className="flex flex-col gap-1">
            <p className="font-bold text-xs md:text-sm leading-none md:leading-none text-ellipsis line-clamp-2">
              {quest.name}
            </p>
            <p className="leading-none text-xs md:text-sm">{numBooksText}</p>
          </div>

          {joinButton()}
        </div>
      </a>
    );
  }

  return (
    <div className="relative flex w-full max-w-[900px] pr-6 md:pr-6 p-4 md:p-6 text-left bg-white border border-offBlack shadow-main rounded-default justify-between">
      <a href={`/quests/${quest.uuid}`} className="no-hover flex gap-4 md:gap-6 w-full cursor-pointer">
        <div className="grid grid-cols-2 gap-2 w-fit flex-none">
          {quest.book_details?.map((book, index) => (
            <BookCover key={index} book={book} size="w-[54px] h-[80px]" clickable={false} />
          ))}
        </div>

        <div className="flex md:flex-grow flex-col justify-between">
          <div className="flex flex-col gap-3">
            <p className="font-bold">{quest.name}</p>
            <div className="hidden md:block text-sm">
              created {quest.created_at} // {numBooksText}
            </div>
            <div className="md:hidden flex flex-col text-xs">
              <div>created {quest.created_at}</div>
              <div>{numBooksText}</div>
            </div>
            <p className="hidden md:block leading-tight">{quest.description}</p>
            <div className="md:hidden flex gap-1">
              {quest.emojis?.map((emoji, index) => (
                <div key={index} className="text-[20px]">
                  {emoji}
                </div>
              ))}
            </div>
          </div>
          <div className="flex w-full justify-between items-center">
            <div className="hidden md:flex gap-3">
              {quest.emojis?.map((emoji, index) => (
                <div key={index} className="text-[24px]">
                  {emoji}
                </div>
              ))}
            </div>
            {joinButton()}
          </div>
        </div>
      </a>
    </div>
  );
};

export default QuestCard;
