import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "#utils/api.js";
import { completeOnboarding } from "./onboardingSlice.js";
import { navigate } from "vike/client/router";

export const fetchCurrentUser = createAsyncThunk("user/fetchCurrentUser", () => api.get(`/users/current`));

export const googleOAuth = createAsyncThunk("user/googleOAuth", (body) => api.post(`/auth/google_oauth`, body));

export const updateUserProfile = createAsyncThunk("user/updateUser", (userData, { rejectWithValue }) =>
  api
    .post(`/users/update_profile`, { user: userData })
    .then((data) => data)
    .catch((error) => rejectWithValue(error.response.data.error))
);

export const updateShowcasedBooks = createAsyncThunk("user/updateShowcasedBooks", (showcasedBookIds) =>
  api.post(`/users/update_showcased_books`, {
    showcased_book_ids: showcasedBookIds,
  })
);

export const signInCallback = (result, dispatch) => {
  if (result.credential) {
    dispatch(googleOAuth({ token: result.credential }));
  } else if (result.code) {
    dispatch(googleOAuth({ code: result.code }));
  } else {
    console.log(result);
    console.error("Error getting credentials from Google");
    dispatch(setAuthError({ error: "Error getting credentials from Google" }));
  }
};

const initialState = {
  currentUserId: null,
  currentUser: null,
  token: null,
  authError: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: () => {
      return initialState;
    },
    setAuthError: (state, action) => {
      return { ...state, authError: action.payload.error };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentUser.fulfilled, (state, action) => {
      return {
        ...state,
        currentUserId: action.payload?.id,
        currentUser: action.payload,
      };
    });
    builder.addCase(googleOAuth.fulfilled, (state, action) => {
      navigate("/feed");
      return {
        ...state,
        currentUserId: action.payload.user.id,
        currentUser: action.payload.user,
        token: action.payload.token,
        authError: null,
      };
    });
    builder.addCase(googleOAuth.rejected, (state, action) => {
      return { ...state, authError: action.error.message };
    });
    builder.addCase(completeOnboarding.fulfilled, (state, action) => {
      return { ...state, currentUser: action.payload };
    });
    builder.addCase(updateUserProfile.fulfilled, (state, action) => {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload,
        },
      };
    });
    builder.addCase(updateShowcasedBooks.fulfilled, (state, action) => {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload,
        },
      };
    });
  },
});

export const { logout, setAuthError } = userSlice.actions;
export default userSlice.reducer;
