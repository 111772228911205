import React, { useState } from "react";
import DOMPurify from "isomorphic-dompurify";

const UserProvidedContent = ({ resource, content, type, onReveal }) => {
  const [hideSpoilerContent, setHideSpoilerContent] = useState(resource.is_spoiler);
  const cleanedContent = DOMPurify.sanitize(content, { USE_PROFILES: { html: true } });

  const showContent = (event) => {
    event.stopPropagation();
    setHideSpoilerContent(false);
    onReveal && setTimeout(onReveal, 10);
  };

  return (
    <>
      {resource.is_flagged || resource.deleted_at ? (
        <p className="p-1 italic bg-beige w-full overflow-x-scroll">
          Cannot render {type} as it has been flagged or deleted.
        </p>
      ) : hideSpoilerContent ? (
        <div
          onClick={showContent}
          className="relative w-full h-[100px] flex items-center justify-center cursor-pointer"
        >
          <div className="absolute w-[98%] h-[80%] blur rounded-default bg-gradient-to-b from-pastelPurple to-pastelGreen" />
          <p className="text-offBlack bg-white rounded-default px-4 py-2 text-sm border border-offBlack z-10">
            View spoiler
          </p>
        </div>
      ) : (
        <p
          className="whitespace-pre-wrap max-w-full leading-snug [overflow-wrap:anywhere]"
          dangerouslySetInnerHTML={{ __html: cleanedContent }}
        />
      )}
    </>
  );
};

export default UserProvidedContent;
