import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import pluralize from "pluralize";
import api from "#utils/api";
import BookCover from "#helpers/BookCover";
import Votes from "#helpers/Votes";
import ChatBubble from "#assets/chat-bubble.svg";
import { openModal } from "#redux/slices/globalUISlice";
import SaveIcon from "#assets/SaveIcon";

const ListCard = ({ listData, commentId }) => {
  const dispatch = useDispatch();
  const { currentUserId } = useSelector((state) => state.user);
  const [list, setList] = useState(listData);
  const [isLoading, setIsLoading] = useState(false);

  if (!list) return;

  useEffect(() => {
    setList(listData);
  }, [listData]);

  const handleUpvote = (event) => {
    event.stopPropagation();
    event.preventDefault();
    api.post(`/lists/${list.uuid}/upvote`).then((res) => {
      setList((prevList) => ({
        ...prevList,
        upvotes: res.upvotes,
        user_vote: res.user_vote,
      }));
    });
  };

  const handleDownvote = (event) => {
    event.stopPropagation();
    event.preventDefault();
    api.post(`/lists/${list.uuid}/downvote`).then((res) => {
      setList((prevList) => ({
        ...prevList,
        upvotes: res.upvotes,
        user_vote: res.user_vote,
      }));
    });
  };

  const handleIconClick = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (!currentUserId) {
      dispatch(openModal({ name: "loginPrompt" }));
    } else {
      setIsLoading(true);
      const params = { type: "List", id: list.id };
      const action = list.is_bookmarked ? api.destroy(`/bookmarks/destroy`, params) : api.post(`/bookmarks`, params);
      action.then(() => {
        setIsLoading(false);
        setList((prevList) => ({ ...prevList, is_bookmarked: !prevList.is_bookmarked }));
      });
    }
  };

  return (
    <a
      href={`/lists/${list.uuid}${commentId ? `?commentId=${commentId}#comments` : ""}`}
      className="no-hover relative flex gap-4 w-full max-w-[900px] max-h-[242px] hover:cursor-pointer bg-white rounded-default p-4 md:p-6 border border-offBlack shadow-main"
    >
      <button
        onClick={handleIconClick}
        className="absolute top-1 md:top-2 right-2 md:right-4 p-1 rounded-full hover:bg-yellow"
        disabled={isLoading}
      >
        <SaveIcon fillColor={list.is_bookmarked ? "#F7DB90" : "transparent"} />
      </button>
      <div className="flex justify-between w-full">
        <div className="flex flex-col gap-1 justify-between">
          <p className="font-bold leading-none">{list.name}</p>
          <div className="flex gap-2 text-xs text-gray">
            <a href={`/users/${list.user?.username}`}>by {list.user?.username}</a>
            <div className="hidden md:block">//</div>
            <div className="hidden md:block">{pluralize("books", list.book_count, true)}</div>
          </div>
          <div className="hidden md:flex text-sm leading-snug text-ellipsis line-clamp-2">{list.description}</div>
          <div className="flex gap-1">
            {list.emojis?.map((emoji, index) => (
              <div key={index} className="text-[20px] md:text-[24px]">
                {emoji}
              </div>
            ))}
          </div>
          <div className="flex flex-row gap-3">
            <Votes
              handleUpvote={handleUpvote}
              handleDownvote={handleDownvote}
              userVote={list.user_vote}
              upvotes={list.upvotes}
            />
            <div className="flex flex-row gap-1 py-1 px-3 justify-center bg-transparent text-gray rounded-default border border-gray">
              <img src={ChatBubble} alt="comments" className="inline" />
              <p className="text-xs">{list.comment_count}</p>
            </div>
          </div>
        </div>
        <div className="hidden md:flex gap-3 mt-4 ml-1 shrink-0">
          {list.book_details?.map((book, index) => (
            <BookCover key={index} book={book} size="w-[54px] h-[80px] md:w-16 md:h-24" clickable={false} />
          ))}
        </div>
      </div>
      <div className="flex md:hidden gap-3 mt-4 ml-1 shrink-0">
        {list.book_details?.slice(0, 2).map((book, index) => (
          <BookCover key={index} book={book} size="w-[54px] h-[80px] md:w-16 md:h-24" clickable={false} />
        ))}
      </div>
    </a>
  );
};

export default ListCard;
